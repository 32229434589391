import React, { useRef, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import { AuthenticatedRoute, UnauthenticatedRoute } from './ProtectedRoutes';
import { Envs } from './conf/envs';
import { useWallet } from '@alephium/web3-react';
import FortuneWheel from './components/wheel/Wheel';

const App = () => {
  const { account } = useWallet();
  const navigate = useNavigate();
  const socketRef = useRef(null);

  React.useEffect(() => {
    if (!socketRef.current) {
      const userAddress = account?.address;
      const token = localStorage.getItem(Envs.TOKEN_NAME);
      socketRef.current = io(`${Envs.SERVER}`, {
        transports: ['websocket'],
        auth: {
          user: userAddress,
          token: token,
        },
      });
  
      // Attach the event listener
      socketRef.current.on('levelUp', ({ level, message }) => {
      
        toast.success(`Such wow, you got 🎉 Level ${level}`);
      });
    }
    socketRef.current.on('connect', () => {
      console.log('Socket connected:', socketRef.current.id);
    });
    
    socketRef.current.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    });
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(); // Clean up socket on unmount
        socketRef.current = null;
      }
    };
  }, [account?.address]); // Dependency array ensures this runs only when `account` changes
  
  return (
    <>
      {/* Toast container for notifications */}
      <ToastContainer position="top-right" autoClose={5000} />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route element={<UnauthenticatedRoute />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route path="/social" element={<Dashboard />} />
          <Route path="/wheel" element={<FortuneWheel />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
