import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import App from "./App";

import { theme } from "./muiTheme";

import { AlephiumWalletProvider } from "@alephium/web3-react"
import { Envs } from "./conf/envs";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useRef } from "react";
const httpLink = new HttpLink({
  uri: `${Envs.SERVER}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // Get the token from localStorage each time a request is made
  const token = localStorage.getItem(Envs.TOKEN_NAME);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.substring(1, token.length - 1)}` : "",
    }
  }

});


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlephiumWalletProvider addressGroup={0} network={"mainnet"} theme="nouns">
        <BrowserRouter>
        <Toaster />
          <App />
        </BrowserRouter>
      </AlephiumWalletProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
