import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { toast } from 'react-hot-toast';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  styled,
  Paper,
  Collapse,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { Envs } from '../conf/envs';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const SET_PREFERENCES = gql`
  mutation SetPreferences($input: AppPreferencesInput!) {
    setPreferences(input: $input)
  }
`;

const SettingsContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  overflow: 'auto',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[400],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
}));

const ColorPickerButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textTransform: 'none',
}));

const Settings = ({ appPreferences, id, fnRefetch }) => {
  const [preferences, setPreferences] = useState({
    id: id,
    title: '',
    headerImg: false,
    headerImgSrc: '',
    headerImgColor: 'rgba(255, 255, 255, 1)',
    backgroundImg: false,
    backgroundImgSrc: '',
    backgroundImgRepeat: false,
    backgroundImgColor: 'rgba(255, 255, 255, 1)',
    token: localStorage.getItem(Envs.TOKEN_NAME).replace(/"/g, ''),
  });

  const [setPreferencesMutation] = useMutation(SET_PREFERENCES);
  const [showHeaderColorPicker, setShowHeaderColorPicker] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);

  useEffect(() => {
    if (appPreferences) {
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        ...appPreferences,
      }));
    }
  }, [appPreferences]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [name]: type === 'checkbox' || type === 'switch' ? checked : value,
    }));
  };

  // Function to check if a color is dark
  const isDarkColor = (color) => {
    // Calculate luminance
    const luminance =
      (0.299 * color.rgb.r + 0.587 * color.rgb.g + 0.114 * color.rgb.b) / 255;
    return luminance < 0.5; // Adjust threshold as needed
  };

  const handleColorChange = (color, name) => {
    if (isDarkColor(color)) {
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        [name]: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
      }));
    } else {
      toast.error('Please select a darker color.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { __typename, ...cleanPreferences } = preferences;
    try {
      await setPreferencesMutation({ variables: { input: cleanPreferences } });
      toast.success('Preferences updated successfully!');
      fnRefetch();
    } catch (error) {
      toast.error('Error updating preferences. Please try again.');
      console.error('Error updating preferences:', error);
    }
  };

  return (
    <SettingsContainer elevation={3}>
      <FormTitle variant="h4">Settings</FormTitle>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          {/* General Settings */}
          <Grid item xs={12}>
            <Card>
              <CardHeader title="General Settings" />
              <CardContent>
                <StyledTextField
                  label="Title"
                  name="title"
                  fullWidth
                  margin="normal"
                  value={preferences.title}
                  onChange={handleChange}
                  variant="outlined"
                />
              </CardContent>
            </Card>
          </Grid>

          {/* Header Settings */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Header Settings" />
              <CardContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences.headerImg}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) setShowHeaderColorPicker(false);
                      }}
                      name="headerImg"
                      color="primary"
                    />
                  }
                  label="Use Header Image"
                />
                {preferences.headerImg ? (
                  <StyledTextField
                    label="Header Image URL"
                    name="headerImgSrc"
                    fullWidth
                    margin="normal"
                    value={preferences.headerImgSrc}
                    onChange={handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      startIcon={<ColorLensIcon />}
                      onClick={() => setShowHeaderColorPicker(!showHeaderColorPicker)}
                    >
                      Choose Header Color
                    </Button>
                    <Collapse in={showHeaderColorPicker}>
                      <SketchPicker
                        color={preferences.headerImgColor}
                        onChangeComplete={(color) =>
                          handleColorChange(color, 'headerImgColor')
                        }
                        // Disable alpha slider if you don't want transparency
                        disableAlpha={false}
                      />
                    </Collapse>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Background Settings */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Background Settings" />
              <CardContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences.backgroundImg}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) setShowBackgroundColorPicker(false);
                      }}
                      name="backgroundImg"
                      color="primary"
                    />
                  }
                  label="Use Background Image"
                />
                {preferences.backgroundImg ? (
                  <>
                    <StyledTextField
                      label="Background Image URL"
                      name="backgroundImgSrc"
                      fullWidth
                      margin="normal"
                      value={preferences.backgroundImgSrc}
                      onChange={handleChange}
                      variant="outlined"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={preferences.backgroundImgRepeat}
                          onChange={handleChange}
                          name="backgroundImgRepeat"
                          color="primary"
                        />
                      }
                      label="Repeat Background Image"
                    />
                  </>
                ) : (
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      startIcon={<ColorLensIcon />}
                      onClick={() =>
                        setShowBackgroundColorPicker(!showBackgroundColorPicker)
                      }
                    >
                      Choose Background Color
                    </Button>
                    <Collapse in={showBackgroundColorPicker}>
                      <SketchPicker
                        color={preferences.backgroundImgColor}
                        onChangeComplete={(color) =>
                          handleColorChange(color, 'backgroundImgColor')
                        }
                        disableAlpha={false}
                      />
                    </Collapse>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Box mt={4} textAlign="center">
              <Button type="submit" variant="contained" color="primary" size="large">
                Save Preferences
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </SettingsContainer>
  );
};

export default Settings;
