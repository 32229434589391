// Updated ForeignProfile.js

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useWallet } from '@alephium/web3-react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Avatar,
  Button,
  Fade,
  styled,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PostIcon from '@mui/icons-material/Article';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { USER_INFO } from '../graphql/query/getUser';
import { CREATE_FOLLOW } from '../graphql/mutations/createFollower';
import { IS_FOLLOWING } from '../graphql/query/isFollowing';
import { FOLLOWERS_COUNT } from '../graphql/query/getFollowers';
import Posts from './PostPerfil';
import ComingSoon from './ComingSoon';

// Utility function to format the address
const formatAddress = (address) => {
  if (!address) return '';
  const start = `${address.slice(0, 5)}`;
  const end = `${address.slice(-4)}`;
  return (
    <span style={{ color: '#007bff' }}>
      {start}...{end}
    </span>
  );
};

// Styled components
const StyledContainer = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  width: '100%',
  backgroundColor: '#121212',
  overflowX: 'hidden',
}));

const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  padding: theme.spacing(2),
  margin: '0 auto',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#1f1f1f',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  marginTop: theme.spacing(2),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#fff',
  '&.Mui-selected': {
    color: '#007bff',
  },
  '&:hover': {
    color: '#007bff',
    opacity: 1,
    transition: 'color 0.3s ease-in-out',
  },
}));

const GlowingDivider = styled('hr')(({ theme }) => ({
  border: 'none',
  height: '2px',
  background: 'linear-gradient(to right, transparent, #007bff, transparent)',
  margin: `${theme.spacing(4)} 0`,
}));

const MainContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));

const FollowButton = styled(Button)(({ theme, isfollowing }) => ({
  marginTop: '15px',
  textTransform: 'none',
  backgroundColor: isfollowing ? 'transparent' : '#007bff',
  color: isfollowing ? '#007bff' : '#fff',
  border: `2px solid #007bff`,
  borderRadius: '30px',
  padding: '10px 30px',
  fontWeight: 'bold',
  boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: isfollowing ? '#007bff' : '#0056b3',
    color: '#fff',
    boxShadow: '0 0 20px rgba(0, 123, 255, 0.7)',
  },
}));

const FollowersCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '15px',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '1.2rem',
  '& svg': {
    marginRight: '8px',
    color: '#007bff',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Fade in={value === index}>
          <Box p={3}>{children}</Box>
        </Fade>
      )}
    </div>
  );
}

const ForeignProfile = ({ address }) => {
  const { account } = useWallet();
  const { address: userAddress } = account || {};

  const [activeView, setActiveView] = useState('posts');
  const [balance, setBalance] = useState(null);
  const [emoji, setEmoji] = useState('');

  // GraphQL queries and mutations
  const { loading, error, data, refetch } = useQuery(USER_INFO, { variables: { address } });
  const {
    loading: isFollowingLoading,
    error: isFollowingError,
    data: isFollowingData,
    refetch: refetchIsFollowing,
  } = useQuery(IS_FOLLOWING, { variables: { address } });
  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
    refetch: refetchFollowers,
  } = useQuery(FOLLOWERS_COUNT, { variables: { address } });
  const [createFollower] = useMutation(CREATE_FOLLOW);

  // Fetch balance and determine emoji
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await fetch(
          `https://node.mainnet.alephium.org/addresses/${address}/balance`
        );
        const data = await response.json();
        const balanceStr = data.balance;

        // Convert balance from string to number with 18 decimals
        const balanceAmount = parseFloat(balanceStr) / 1e18;

        // Round to 2 decimal places
        const roundedBalance = Math.round(balanceAmount * 100) / 100;

        setBalance(roundedBalance);

        // Determine emoji based on balance
        let newEmoji = '';
        if (roundedBalance < 10) {
          newEmoji = '🦐'; // Shrimp
        } else if (roundedBalance < 100) {
          newEmoji = '🐟'; // Fish
        } else if (roundedBalance < 5000) {
          newEmoji = '🦈'; // Shark
        } else {
          newEmoji = '🐳'; // Whale
        }

        setEmoji(newEmoji);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    if (address) {
      fetchBalance();
    }
  }, [address]);

  // Refetch data when address changes
  useEffect(() => {
    if (address) {
      refetch();
      refetchIsFollowing();
      refetchFollowers();
    }
  }, [address, refetch, refetchIsFollowing, refetchFollowers]);

  const handleFollow = async () => {
    const actionType = isFollowingData?.isFollowing ? 'Unfollow' : 'Follow';
    try {
      await createFollower({
        variables: {
          createFollowerInput: {
            actionToId: address,
            action: actionType,
          },
        },
      });
      refetchIsFollowing();
      refetchFollowers();
    } catch (err) {
      console.error(`Error with ${actionType}:`, err);
    }
  };

  // Aggregate loading and error states
  const isLoading = loading || isFollowingLoading || followersLoading;
  const hasError = error || isFollowingError || followersError;

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        bgcolor="#121212"
      >
        <CircularProgress style={{ color: '#007bff' }} />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        bgcolor="#121212"
      >
        <Typography variant="h6" style={{ color: '#FF4C4C' }}>
          Error: {error?.message || isFollowingError?.message || followersError?.message}
        </Typography>
      </Box>
    );
  }

  const isFollowing = isFollowingData?.isFollowing;
  const followersCount = followersData?.followersByUser || 0;

  const handleChange = (event, newValue) => {
    setActiveView(newValue);
  };

  return (
    <StyledContainer>
      <MainContent>
        <Content>
          <Box textAlign="center" mt={6}>
        
            <Typography variant="subtitle1" style={{ color: '#007bff' }}>
              {formatAddress(data.userInfo.address)} {emoji}
            </Typography>

            <FollowersCount>
              <GroupIcon />
              <Typography variant="body1">{followersCount} Followers</Typography>
            </FollowersCount>

            <FollowButton
              variant="contained"
              onClick={handleFollow}
              isfollowing={isFollowing ? 1 : 0}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </FollowButton>
          </Box>

          <GlowingDivider />

          <StyledTabs
            value={activeView}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#007bff',
                height: 3,
              },
            }}
          >
            <StyledTab value="posts" label="Posts" icon={<PostIcon />} />
            <StyledTab value="portfolio" label="Portfolio" icon={<WorkOutlineIcon />} />
          </StyledTabs>

          <TabPanel value={activeView} index="posts">
            <Posts address={address} />
          </TabPanel>
          <TabPanel value={activeView} index="portfolio">
            <ComingSoon />
          </TabPanel>
        </Content>
      </MainContent>
    </StyledContainer>
  );
};

export default ForeignProfile;
