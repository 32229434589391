import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  IconButton,
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery, // Import useMediaQuery
} from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EmojiPicker from 'emoji-picker-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@apollo/client';
import { mutualFollowers } from '../../graphql/query/mutualFollowers';
import { ANS } from '@alph-name-service/ans-sdk';
import { io } from 'socket.io-client';
import { Me } from '../../graphql/query/ME';
import { Envs } from '../../conf/envs';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const ChatContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 93vh;
  width: 100vw;
  background: linear-gradient(120deg, #1c1c1c, #2c2c2c);
  color: #e0e0e0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FriendsList = styled(motion.div)`
  position: relative;
  width: 25%;
  background: linear-gradient(135deg, rgba(40, 44, 52, 1), rgba(20, 20, 20, 1));
  border-right: 1px solid #555;
  padding: 1rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    max-width: 300px;
    z-index: 1000;
    border-right: none;
    transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0%)' : 'translateX(-100%)'};
  }
`;

const FriendItem = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  color: #e0e0e0;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ChatWindow = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(40, 44, 52, 1), rgba(20, 20, 20, 1));
  position: relative;

  @media (max-width: 768px) {
    height: calc(100vh - 70px); /* Adjust height to fit with the input on mobile */
  }
`;

const SlidingInputContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  right: 0;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    transform: translateY(0);
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(30, 30, 30, 0.9);
    padding: 1rem;
    z-index: 1000;
  }
`;

const ChatHeaderWithTools = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(40, 44, 52, 1), rgba(20, 20, 20, 1));
  position: relative;
`;

const ChatBox = styled(Box)`
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px; /* Add padding to account for input height */

  @media (max-width: 768px) {
    padding-bottom: 70px; /* Adjust for smaller input on mobile */
  }
`;
const MessageBubble = styled(motion.div)`
  max-width: 80%;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 20px;
  background: ${(props) =>
    props.isUser
      ? 'linear-gradient(135deg, #4a90e2, #007bff)'
      : 'linear-gradient(135deg, #555, #333)'};
  color: ${(props) => (props.isUser ? '#fff' : '#e0e0e0')};
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  box-shadow: ${(props) =>
    props.isUser
      ? '0 4px 10px rgba(0, 123, 255, 0.5)'
      : '0 4px 10px rgba(0, 0, 0, 0.2)'};
  transition: all 0.3s ease;

  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;

  &:hover {
    transform: scale(1.03);
    box-shadow: ${(props) =>
    props.isUser
      ? '0 6px 12px rgba(0, 123, 255, 0.6)'
      : '0 6px 12px rgba(0, 0, 0, 0.3)'};
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-top: 1px solid #444;
  background-color: rgba(30, 30, 30, 0.9);
  position: fixed; /* Fix it to the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Ensure it stays on top of other content */

  @media (max-width: 768px) {
    padding: 0.5rem; /* Adjust padding for mobile */
    border-radius: 0; /* Remove border radius on mobile for full-width look */
  }
`;
const EmojiContainer = styled(Box)`
  position: absolute;
  bottom: 70px;
  right: 20px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    bottom: 120px;
    right: 10px;
  }
`;

const ChatComponent = () => {
  const [friends, setFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isFriendsListOpen, setIsFriendsListOpen] = useState(false);
  const [safeMessage, setSafeMessage] = useState(false);
  const [definePassDialog, setDefinePassDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [storePassword, setStorePassword] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false); // New state for loading indicator

  const { data, loading, error } = useQuery(mutualFollowers);
  const { data: dataMe } = useQuery(Me);
  const userAddress = dataMe?.me?.address;
  const token = localStorage.getItem(Envs.TOKEN_NAME);
  const socketRef = useRef(null);
  const chatBoxRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:768px)'); // Use useMediaQuery to detect mobile devices
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    if (safeMessage && !definePassDialog) {
      setDefinePassDialog(true);
      const getPass = localStorage.getItem(
        `${userAddress}_${selectedFriend?.address}`
      );
      if (getPass) setPassword(getPass);
    } else if (!safeMessage && !!definePassDialog) {
      setDefinePassDialog(false);
    }
  }, [safeMessage]);

  useEffect(() => {
    if (userAddress) {
      if (!socketRef.current) {
        socketRef.current = io(`${Envs.SERVER}`, {
          transports: ['websocket'],
          auth: {
            user: userAddress,
            token: token,
          },
        });

        // Connection events
        socketRef.current.on('connect', () => {
          console.log('connected with user address:', userAddress);
        });

        socketRef.current.on('disconnect', () => {
          console.log('disconnected');
        });
      }

      // Attach event listeners
      const handleChatMessages = (response) => {
        console.log('Messages:', response);
        setMessages(response.res?.messages);
      };

      const handleNewMessage = (message) => {
        console.log('New message received:', message);
        setMessages((prevMessages) => [...prevMessages, message.message]);
      };

      socketRef.current.on('chatMessages', handleChatMessages);
      socketRef.current.on('newMessage', handleNewMessage); // Add this listener

      // Clean up
      return () => {
        if (socketRef.current) {
          socketRef.current.off('chatMessages', handleChatMessages);
          socketRef.current.off('newMessage', handleNewMessage); // Clean up this listener
        }
      };
    }
  }, [userAddress]);

  const handlePasswordSubmit = () => {
    const key = `${userAddress}_${selectedFriend?.address}`;

    if (storePassword) {
      // Save the password locally
      localStorage.setItem(key, password);
    }

    setDefinePassDialog(false);
  };

  const handleSafeModeToggle = () => {
    const key = `${userAddress}_${selectedFriend?.address}`;

    if (!safeMessage) {
      // Enabling safe mode
      const savedPassword = localStorage.getItem(key);
      if (savedPassword) {
        setPassword(savedPassword);
      } else {
        setDefinePassDialog(true);
      }
    }

    setSafeMessage(!safeMessage);
  };

  // Simple hash function to generate a seed from the password
  const simpleHash = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash * 31 + str.charCodeAt(i)) & 0x7fffffff;
    }
    return hash;
  };

  // Pseudo-random number generator (Linear Congruential Generator)
  const PRNG = (seed) => {
    let m = 0x80000000; // 2^31
    let a = 1103515245;
    let c = 12345;
    let state = seed ? seed : Math.floor(Math.random() * (m - 1));

    return function () {
      state = (a * state + c) % m;
      return state / (m - 1);
    };
  };

  const encryptWithPassword = (message) => {
    if (!password) {
      console.error('Encryption password is not set.');
      return '';
    }

    const seed = simpleHash(password);
    const prng = PRNG(seed);

    // Encode message to Base64 before encryption
    const messageBase64 = btoa(message);

    // Convert Base64 message to Uint8Array
    const encoder = new TextEncoder();
    const messageBytes = encoder.encode(messageBase64);

    // Encrypt the bytes at the bit level
    const encryptedBits = [];
    for (let byte of messageBytes) {
      for (let i = 7; i >= 0; i--) {
        const bit = (byte >> i) & 1;
        const randBit = Math.floor(prng() * 2);
        const encryptedBit = bit ^ randBit;
        encryptedBits.push(encryptedBit);
      }
    }

    // Convert encrypted bits to bytes
    const encryptedBytes = [];
    for (let i = 0; i < encryptedBits.length; i += 8) {
      let byte = 0;
      for (let j = 0; j < 8; j++) {
        if (i + j < encryptedBits.length) {
          byte = (byte << 1) | encryptedBits[i + j];
        } else {
          byte = byte << 1;
        }
      }
      encryptedBytes.push(byte);
    }

    // Encode to Base64
    const encryptedBase64 = btoa(String.fromCharCode(...encryptedBytes));

    // Add a prefix to mark the message as encrypted
    const encryptedWithPrefix = `ENCRYPTED:${encryptedBase64}`;
    return encryptedWithPrefix;
  };

  const decryptWithPassword = (message) => {
    const prefix = 'ENCRYPTED:';

    // Check if the message has the encryption prefix
    if (!message?.startsWith(prefix)) {
      console.log('Message is not encrypted. Returning as is.');
      return message; // Return the original message if not encrypted
    }

    if (!password) {
      console.error('Decryption password is not set.');
      return '';
    }

    const seed = simpleHash(password);
    const prng = PRNG(seed);

    // Remove the prefix before decryption
    const encryptedBase64 = message.slice(prefix.length);

    // Decode from Base64
    let encryptedBytesStr;
    try {
      encryptedBytesStr = atob(encryptedBase64);
    } catch (e) {
      console.error(
        'Failed to decode Base64 during decryption. Possible corrupted data.'
      );
      return encryptedBase64;
    }

    const encryptedBytes = new Uint8Array(encryptedBytesStr.length);
    for (let i = 0; i < encryptedBytesStr.length; i++) {
      encryptedBytes[i] = encryptedBytesStr.charCodeAt(i);
    }

    // Convert encrypted bytes to bits
    const encryptedBits = [];
    for (let byte of encryptedBytes) {
      for (let i = 7; i >= 0; i--) {
        const bit = (byte >> i) & 1;
        encryptedBits.push(bit);
      }
    }

    // Decrypt the bits
    const decryptedBits = [];
    for (let bit of encryptedBits) {
      const randBit = Math.floor(prng() * 2);
      const decryptedBit = bit ^ randBit;
      decryptedBits.push(decryptedBit);
    }

    // Convert decrypted bits to bytes
    const decryptedBytes = [];
    for (let i = 0; i < decryptedBits.length; i += 8) {
      let byte = 0;
      for (let j = 0; j < 8 && i + j < decryptedBits.length; j++) {
        byte = (byte << 1) | decryptedBits[i + j];
      }
      decryptedBytes.push(byte);
    }

    // Convert bytes to string
    const decoder = new TextDecoder();
    let decryptedBase64;
    try {
      decryptedBase64 = decoder.decode(new Uint8Array(decryptedBytes));
    } catch (e) {
      console.error(
        'Failed to decode decrypted bytes. Possible incorrect password or corrupted data.'
      );
      return decryptedBase64;
    }

    // Decode from Base64 to get original message
    let decryptedMessage;
    try {
      decryptedMessage = atob(decryptedBase64);
    } catch (e) {
      console.error(
        'Failed to decode Base64 after decryption. Possible incorrect password or corrupted data.'
      );
      return message;
    }

    return decryptedMessage;
  };

  useEffect(() => {
    if (data && data.mutualFollowers) {
      // Initialize friends with address as name
      const initialFriends = data.mutualFollowers.map((friend) => ({
        ...friend,
      }));
      setFriends(initialFriends);

      // Fetch ANS names asynchronously for each friend
      data.mutualFollowers.forEach(async (friend) => {
        const ansName = await fetchAnsName(friend.address);
        if (ansName) {
          setFriends((prevFriends) =>
            prevFriends.map((f) =>
              f.id === friend.id ? { ...f, name: ansName } : f
            )
          );
        }
      });
    }
  }, [data]);

  // Function to fetch the ANS name
  const fetchAnsName = async (addressToLookup) => {
    try {
      const ans = new ANS('testnet');
      const profile = await ans.getProfile(addressToLookup);
      return profile ? profile.name : null;
    } catch (error) {
      console.error('Error fetching ANS profile:', error);
      return null;
    }
  };

  const startConversation = (friend) => {
    if (friend && friend.address) {
      setSelectedFriend(friend);
      setIsFriendsListOpen(false); // Close the friends list on mobile when a friend is selected
      socketRef.current.emit('hasChat', {
        participants: [friend.address, userAddress],
      });
    }
  };

  useEffect(() => {
    if (selectedFriend) {
      const handleChatInitiateResponse = (response) => {
        console.log('initiateResponse:', response);

        if (!response.response) {
          console.log('initiating conversation');
          socketRef.current.emit('chatInitiate', {
            participants: [selectedFriend.address, userAddress],
          });
        } else {
          // Emit getChat now that selectedFriend is set
          socketRef.current.emit('getChat', {
            participants: [selectedFriend.address, userAddress],
          });
        }
      };

      socketRef.current.on('chatInitiateResponse', handleChatInitiateResponse);

      // Clean up to prevent duplicate listeners
      return () => {
        socketRef.current.off(
          'chatInitiateResponse',
          handleChatInitiateResponse
        );
      };
    }
  }, [selectedFriend, userAddress]);

  const sendMessage = async () => {
    if (newMessage.trim()) {
      let messageToSend;
      if (!!password)
        messageToSend = safeMessage ? encryptWithPassword(newMessage) : newMessage;
      else {
        messageToSend = newMessage;
      }
      socketRef.current.emit('setMessage', {
        participants: [userAddress, selectedFriend.address],
        message: messageToSend,
      });

      setMessages((prevMessages) => [
        ...prevMessages,
        { content: messageToSend, sender: userAddress },
      ]);

      setNewMessage('');
    }
  };

  const highlightAddress = (address) => {
    if (!address) return '';
    const start = `${address.slice(0, 5)}`;
    const end = `${address.slice(-4)}`;
    return (
      <span>
        <a style={{ color: '#007bff' }}>{start}</a>
        <a style={{ color: 'white' }}>...{end}</a>
      </span>
    );
  };

  const handleEmojiClick = (emojiObject) => {
    setNewMessage((prev) => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    setIsTyping(e.target.value.length > 0);
  };

  return (
    <ChatContainer>
      {/* Friends List */}
      <FriendsList
        isOpen={isFriendsListOpen}
        initial={{ x: isMobile ? '-100%' : '0%' }} // Adjusted initial position
        animate={{ x: isMobile ? (isFriendsListOpen ? '0%' : '-100%') : '0%' }} // Adjusted animate position
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h5"
            sx={{ marginBottom: '1rem', fontWeight: 'bold' }}
          >
            Chats
          </Typography>
          {/* Close button for mobile */}
          <IconButton
            onClick={() => setIsFriendsListOpen(false)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <CloseIcon sx={{ color: '#e0e0e0' }} />
          </IconButton>
        </Box>
        {friends.map((friend, index) => (
          <FriendItem
            key={index}
            onClick={() => startConversation(friend)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              backgroundColor: selectedFriend === friend ? "rgba(0,0,0,0.3)" : "transparent", // Highlight if selected
              border: selectedFriend === friend ? "2px solid #007BFF" : "none", // Regular border
              boxShadow: selectedFriend === friend ? "0 0 10px #007BFF, 0 0 20px #007BFF" : "none", // Glowing effect
              transition: "box-shadow 0.3s ease-in-out, border 0.3s ease-in-out", // Smooth transition
            }}
          >
            <Typography variant="subtitle1">
              {friend.name || highlightAddress(friend.address)}
            </Typography>
          </FriendItem>
        ))}
      </FriendsList>

      {/* Chat Window */}
      <ChatWindow>
        <ChatHeaderWithTools>
          <Box display="flex" alignItems="center">
            {/* Menu button for mobile */}
            <IconButton
              onClick={() => setIsFriendsListOpen(true)}
              sx={{ display: { xs: 'block', md: 'none' }, marginRight: '0.5rem' }}
            >
              <MenuIcon sx={{ color: '#e0e0e0' }} />
            </IconButton>

            {/* Lock Icon */}
            <Box
              sx={{
                display: 'inline-block',
                '&:hover': { animation: 'pulse 0.5s ease-in-out' },
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)', color: 'inherit' },
                  '100%': { transform: 'scale(1.2)', color: 'greenyellow' },
                },
              }}
            >
              {!safeMessage ? (
                <LockOpenIcon onClick={handleSafeModeToggle} />
              ) : (
                <LockIcon
                  sx={{ color: 'greenyellow' }}
                  onClick={handleSafeModeToggle}
                />
              )}
            </Box>
          </Box>

          {/* Sliding Input Line */}
          {definePassDialog && (
            <SlidingInputContainer
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              <TextField
                size="small"
                variant="outlined"
                placeholder="Enter encryption key"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  backgroundColor: 'rgba(30, 30, 30, 0.9)',
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& fieldset': {
                    borderColor: 'greenyellow',
                  },
                  '&:hover fieldset': {
                    borderColor: 'limegreen',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'limegreen',
                    boxShadow: '0 0 5px rgba(50, 255, 50, 0.8)',
                  },
                  width: isMobile ? '100%' : 'auto', // Adjust width on mobile
                  marginBottom: isMobile ? '1rem' : '0', // Add margin on mobile
                }}
              />
              <Box
                display="flex"
                flexDirection={isMobile ? 'row' : 'row'}
                gap="0.5rem"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handlePasswordSubmit}
                  sx={{
                    backgroundColor: 'greenyellow',
                    color: '#000',
                    '&:hover': {
                      backgroundColor: '#9be52a',
                    },
                    width: isMobile ? '48%' : 'auto', // Adjust width on mobile
                  }}
                >
                  Set
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setStorePassword(true);
                    handlePasswordSubmit();
                  }}
                  sx={{
                    backgroundColor: 'greenyellow',
                    color: '#000',
                    '&:hover': {
                      backgroundColor: '#9be52a',
                    },
                    width: isMobile ? '48%' : 'auto', // Adjust width on mobile
                  }}
                >
                  Save
                </Button>
              </Box>
            </SlidingInputContainer>
          )}
        </ChatHeaderWithTools>

        {/* Chat Content */}
        {selectedFriend ? (
          <>
            <ChatBox ref={chatBoxRef}>
              <AnimatePresence>
                {messages?.map((msg, index) => {
                  const isEncrypted = msg?.content?.startsWith('ENCRYPTED:');
                  const displayedContent =
                    safeMessage && isEncrypted
                      ? decryptWithPassword(msg.content)
                      : msg.content;

                  return (
                    <MessageBubble
                      key={index}
                      isUser={msg.sender === userAddress}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      sx={{
                        backgroundColor: safeMessage
                          ? msg.sender === userAddress
                            ? 'rgba(0, 100, 0, 0.8)'
                            : 'rgba(30, 50, 30, 0.8)'
                          : msg.sender === userAddress
                            ? '#007bff'
                            : '#555',
                        color: '#fff',
                        boxShadow: safeMessage
                          ? '0 4px 15px greenyellow'
                          : '0 2px 5px rgba(0, 0, 0, 0.2)',
                        border: safeMessage ? '1px solid greenyellow' : 'none',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      {displayedContent}
                    </MessageBubble>
                  );
                })}
              </AnimatePresence>
            </ChatBox>

            {/* Message Input */}
            <InputContainer
              sx={{
                backgroundColor: safeMessage
                  ? 'rgba(0, 50, 0, 0.8)'
                  : 'rgba(30, 30, 30, 0.9)',
                borderRadius: '20px',
                transition: 'all 0.3s ease',
                boxShadow: 'none',
              }}
            >


              <TextField
                fullWidth
                variant="outlined"
                value={newMessage}
                onChange={handleInputChange}
                placeholder={
                  safeMessage ? 'Private message...' : 'Type a message...'
                }
                sx={{
                  marginRight: '0.5rem',
                  backgroundColor: '#2a2a2a',
                  borderRadius: '20px',
                  color: '#fff',
                  boxShadow: safeMessage ? '0 0 10px greenyellow' : 'none',
                  transition: 'all 0.3s ease',
                }}
                InputProps={{
                  style: {
                    borderRadius: '20px',
                    color: safeMessage ? 'greenyellow' : '#fff',
                  },
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    sendMessage();
                  }
                }}
              />

              <IconButton
                onClick={sendMessage}
                sx={{
                  color: safeMessage ? 'greenyellow' : '#4f93f7',
                  transition: 'color 0.3s ease, transform 0.3s ease',
                  '&:hover': {
                    transform: safeMessage ? 'scale(1.2)' : 'scale(1.1)',
                    color: safeMessage ? 'lime' : '#007bff',
                  },
                }}
              >
                <SendIcon />
              </IconButton>

              {showEmojiPicker && (
                <EmojiContainer>
                  <EmojiPicker
                    onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject)}
                    theme="dark"
                  />
                </EmojiContainer>
              )}
            </InputContainer>
          </>
        ) : (
          <Box
            sx={{
              margin: 'auto',
              textAlign: 'center',
              color: '#888',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
              Welcome to ChatApp
            </Typography>
            <Typography variant="body1">
              Select a friend to start a conversation
              <br /> (only mutual followers will show in the friends list!)
            </Typography>
          </Box>
        )}
      </ChatWindow>
    </ChatContainer>
  );
};

export default ChatComponent;
