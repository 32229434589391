import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  50% {
    transform: scale(1.05);
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
`;

const typing = keyframes`
  from {
    width: 0;
  }
  to {
    width: 9ch; /* Adjusted width to fit the entire text */
  }
`;

const blink = keyframes`
  0%, 100% {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
`;

const ComingSoon = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 64px)', // Adjust if there's a header/footer
        animation: `${fadeIn} 2s ease-in-out`,
        '&::after': {
          content: '""',
          position: 'absolute',
          inset: 0,
          zIndex: -1,
        },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '3rem', sm: '4rem', md: '5rem' },
          fontWeight: 'bold',
          color: 'white',
          zIndex: 2,
          animation: `${pulse} 2s infinite`,
          textAlign: 'center',
          margin: 0,
        }}
      >
        Coming Soon
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
          color: 'white',
          marginTop: 2,
          zIndex: 2,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          borderRight: '0.15em solid white',
          animation: `${typing} 4s steps(40, end) forwards, ${blink} 1s infinite`,
          animationDelay: '1s',
          width: '0',
        }}
      >
        Stay tuned!
      </Typography>
    </Box>
  );
};

export default ComingSoon;
