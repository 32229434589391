// Navbar.js
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  Badge,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import {
  Home as HomeIcon,
  RocketLaunch as RocketLaunchIcon,
  ConnectWithoutContact as ConnectWithoutContactIcon,
  Storefront as StorefrontIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@alephium/web3-react';
import { isMobile } from 'react-device-detect';

// Define the keyframes for the pulsating effect
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

// Styled component for the pulsating ball
const PulsatingBall = styled('div')`
  width: 15px;
  height: 15px;
  background-color: #007bff;
  border-radius: 50%;
  animation: ${pulse} 2s infinite;
`;

const Navbar = (props) => {
  const { account,connectionStatus } = useWallet();
  const navigate = useNavigate();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  const highlightAddress = (address) => {
    if (!address) return '';
    const start = `${address.slice(0, 5)}`;
    const end = `${address.slice(-4)}`;
    return (
      <span>
        <a style={{ color: '#007bff' }}>{start}</a>
        <a style={{ color: 'white' }}>...{end}</a>
      </span>
    );
  };

  // Handle opening the mobile menu
  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  // Handle closing the mobile menu
  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  // Handle menu item clicks
  const handleMenuItemClick = (option) => {
    props.setSet(option);
    handleMobileMenuClose();
  };

  // Mobile menu items
  const mobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="mobile-menu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleMenuItemClick('social')}>
        <ConnectWithoutContactIcon sx={{ mr: 1 }} />
        Social
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('pulse')}>
        <PulsatingBall sx={{ mr: 1 }} />
        Pulse
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('alphpad')}>
        <RocketLaunchIcon sx={{ mr: 1 }} />
        Alphpad
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('marketplace')}>
        <StorefrontIcon sx={{ mr: 1 }} />
        Marketplace
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('perfil')}>
        <HomeIcon sx={{ mr: 1 }} />
        Perfil
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar sx={{ backgroundColor: 'rgba(0,0,0,0.1)' }} position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => {
              props.setSet('perfil');
            }}
            sx={{ textTransform: 'none', color: 'white' }}
          >
            <Typography variant="h6" component="div">
              {highlightAddress(account?.address) || 'ALPHPOST'}
            </Typography>
          
          </Button>
        </Box>

        {/* Notification Center */}
        <IconButton color="inherit" sx={{ mr: 2 }}>
          <Badge badgeContent={1} color="primary" onClick={() => {
              props.setSet('chat');
            }}>
            <SendIcon/>
          </Badge>
        </IconButton>

        {/* Desktop Menu Items */}
        {!isMobile && (
          <Box>
            <Tooltip title="Social">
              <IconButton
                color="inherit"
                onClick={() => {
                  props.setSet('social');
                }}
              >
                <ConnectWithoutContactIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Pulse">
              <IconButton
                color="inherit"
                onClick={() => {
                  props.setSet('pulse');
                }}
              >
                <PulsatingBall />
              </IconButton>
            </Tooltip>
            <Tooltip title="Alphapad">
              <IconButton
                color="inherit"
                onClick={() => {
                  props.setSet('alphpad');
                }}
              >
                <RocketLaunchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Marketplace">
              <IconButton
                color="inherit"
                onClick={() => {
                  props.setSet('marketplace');
                }}
              >
                <StorefrontIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {/* Mobile Hamburger Menu */}
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
      {/* Render mobile menu */}
      {isMobile && mobileMenu}
    </AppBar>
  );
};

export default Navbar;
