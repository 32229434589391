// Updated Perfil.js

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Me } from '../graphql/query/ME';
// Add the correct import
import { useWallet } from '@alephium/web3-react';
import {
  Box,
  Typography,
  styled,
  Tabs,
  Tab,
  Divider,
  Avatar,
  CircularProgress,
  Fade,
  useTheme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PostIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People'; // Icon for followers
import Posts from './PostPerfil';
import Settings from './Settings';
import ComingSoon from './ComingSoon';
import { FOLLOWERS_COUNT } from '../graphql/query/getFollowers';
import { GET_EXP_POINT } from '../graphql/query/posts copy';

// Utility function to format the address
const formatAddress = (address) => {
  if (!address) return '';
  const start = `${address.slice(0, 5)}`;
  const end = `${address.slice(-4)}`;
  return (
    <span style={{ color: '#007bff' }}>
      {start}...{end}
    </span>
  );
};

// Styled components
const StyledContainer = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  width: '100%',
  backgroundColor: '#121212',
  overflowX: 'hidden',
}));

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '35vh',
  backgroundColor: '#1f1f1f',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(4),
  position: 'relative',
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-50px',
  borderRadius: '50%',
  border: `5px solid #121212`,
  width: 100,
  height: 100,
  overflow: 'hidden',
  backgroundColor: '#007bff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 0 10px #007bff',
    transition: 'all 0.3s ease-in-out',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  padding: theme.spacing(2),
  margin: '0 auto',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#1f1f1f',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  marginTop: theme.spacing(2),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#fff',
  '&.Mui-selected': {
    color: '#007bff',
  },
  '&:hover': {
    color: '#007bff',
    opacity: 1,
    transition: 'color 0.3s ease-in-out',
  },
}));

const GlowingDivider = styled(Divider)(({ theme }) => ({
  background: 'linear-gradient(to right, transparent, #007bff, transparent)',
  height: 2,
  margin: `${theme.spacing(4)} 0`,
}));

const MainContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(12),
}));

const FollowersBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),

  borderRadius: theme.shape.borderRadius,
  textShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
  marginTop: theme.spacing(2),
}));

const FollowersCountText = styled(Typography)(({ theme }) => ({
  color: '#007bff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  textShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)', // Slightly enlarges on hover
  },
}));

const FollowersLabelText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: '0.9rem',
  fontWeight: 500,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Fade in={value === index}>
          <Box p={3}>{children}</Box>
        </Fade>
      )}
    </div>
  );
}

const Perfil = () => {
  const theme = useTheme();
  const { account } = useWallet();
  const { address } = account || {};
  const { loading, error, data, refetch } = useQuery(Me);
  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
    refetch: refetchFollowers,
  } = useQuery(FOLLOWERS_COUNT, { variables: { address } });
  const { loading: xpLoading, error: xpError, data: xpData, refetch: refetchXp } = useQuery(GET_EXP_POINT, {
    variables: { address },
  });

  useEffect(() => {
    if (address) {
      refetch();
      refetchFollowers();
      refetchXp();
    }
  }, [address, refetch, refetchFollowers]);
  useEffect(() => {
    refetch();
    refetchFollowers();
    refetchXp();
  }, [])
  const [activeView, setActiveView] = useState('posts');

  const handleChange = (event, newValue) => {
    setActiveView(newValue);
  };

  if (loading || followersLoading || xpLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        bgcolor="#121212"
      >
        <CircularProgress style={{ color: '#007bff' }} />
      </Box>
    );

  if (error || followersError || xpError)
    return <div>Error! {error?.message || followersError?.message || xpError?.message}</div>;

  return (
    <StyledContainer>
      <MainContent>
        <Content>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            {/* Level Progress */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={1}
              sx={{
                background: 'linear-gradient(to right, #1f1f1f, #1f1f1f)',
                borderRadius: '20px',
                boxShadow: '0 0 15px rgba(0, 123, 255, 0.5)',
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
              }}
            >

              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  background: 'linear-gradient(to right, #007bff, #00d4ff)',
                  borderRadius: 'inherit',
                  width: `${(xpData?.experiencePoint.count / xpData?.experiencePoint.nextLevelXp) * 100 || 0}%`,
                  boxShadow: '0 0 15px rgba(0, 123, 255, 0.5)',
                  transition: 'width 0.3s ease',
                }}
              />
              <Typography
                variant="subtitle1"
                style={{
                  color: '#fff',
                  fontWeight: '500',
                  zIndex: 1,
                }}
              >
                {`Level ${xpData?.experiencePoint?.level || 1}`}
              </Typography>
              <Typography
                variant="caption"
                style={{
                  color: '#ccc',
                  fontWeight: '400',
                  zIndex: 1,
                }}
              >
                {`${xpData?.experiencePoint.count || 0} / ${xpData?.experiencePoint.nextLevelXp || 1000} XP`}
              </Typography>
            </Box>


            {/* Followers Count */}
            <FollowersBox>
              <PeopleIcon style={{ color: '#007bff', fontSize: '1.5rem' }} />
              <FollowersCountText>{followersData?.followersByUser || 0}</FollowersCountText>
              <FollowersLabelText>Followers</FollowersLabelText>
            </FollowersBox>
          </Box>

          <GlowingDivider />

          <StyledTabs
            value={activeView}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#007bff',
                height: 3,
              },
            }}
          >
            <StyledTab value="posts" label="Posts" icon={<PostIcon />} />
            <StyledTab value="portfolio" label="Portfolio" icon={<WorkOutlineIcon />} />
          </StyledTabs>

          <TabPanel value={activeView} index="posts">
            <Posts address={address} />
          </TabPanel>
          <TabPanel value={activeView} index="portfolio">
            <ComingSoon />
          </TabPanel>
        </Content>
      </MainContent>
    </StyledContainer>
  );
};

export default Perfil;
