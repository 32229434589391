
import { gql } from '@apollo/client';

export const GET_POSTS = gql`
  query posts($order: String, $author: String, $search: String, $offset: Int, $limit: Int) {
    posts(order: $order, author: $author, search: $search, offset: $offset, limit: $limit) {
      _id
      content
      author
      createdAt
      likes {
        user
        when
      }
      comments {
        id
        content
        user
        timestamp
      }
    }
  }
`;



export const GET_POST = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      _id
      content
      author
      timestamp
      likes {
        user
        when
      }
      comments {
        id
        content
        user
        timestamp
      }
    }
  }
`;
