import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Wheel.css";

const FortuneWheel = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [prize, setPrize] = useState(null);

  const navigate = useNavigate();
  const squares = [
    { text: "+15 $ALPH", color: "#99ccff" },
    { text: "+100 $ALPH", color: "#99ccff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "+1 $ALPH", color: "#66b3ff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+15 $ALPH", color: "#99ccff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+1 $ALPH", color: "#66b3ff" },
    { text: "+50 $POST", color: "#3399ff" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "NOTHING", color: "#111" },
    { text: "+1 $ALPH", color: "#66b3ff" },
  ];

  const squareWidth = 150; // Width of each square
  const totalSquares = squares.length; // Number of unique squares
  const totalWidth = squareWidth * totalSquares; // Width of one full cycle
  const containerRef = useRef(null); // Ref to the container for direct manipulation

  const spinRoulette = () => {
    if (isSpinning) return;

    setIsSpinning(true);
    setPrize(null);

    const randomStop = Math.floor(Math.random() * totalSquares); // Random prize index
    const loops = 5; // Full loops before stopping
    const targetOffset = loops * totalWidth + randomStop * squareWidth; // Total distance to scroll
    const duration = 4000; // Total animation duration in ms

    let start = null;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;

      if (elapsed < duration) {
        const easing = 1 - Math.pow(1 - elapsed / duration, 3); // Smooth cubic ease-out
        const currentOffset = targetOffset * easing;

        // Set the container's transform property directly
        containerRef.current.style.transform = `translateX(-${
          currentOffset % totalWidth
        }px)`;

        requestAnimationFrame(animate);
      } else {
        // Align to final square and determine the prize
        const finalOffset = randomStop * squareWidth;
        containerRef.current.style.transform = `translateX(-${finalOffset}px)`;
        setPrize(squares[randomStop].text);
        setIsSpinning(false);

        // Redirect after 5 seconds
        setTimeout(() => {
          navigate("/social");
        }, 2000);
      }
    };

    requestAnimationFrame(animate);
  };

  return (
    <div className="moving-squares-roulette-container">
      <h1>Fortune Wheel</h1>
      <div className="roulette">
        <div className="indicator"></div>
        <div className="squares-container" ref={containerRef}>
          {[...squares, ...squares, ...squares].map((square, index) => (
            <div
              key={index}
              className="square"
              style={{ backgroundColor: square.color }}
            >
              {square.text}
            </div>
          ))}
        </div>
      </div>
      <button className="btnWheel" onClick={spinRoulette} disabled={isSpinning}>
        {isSpinning ? "Spinning..." : "Spin"}
      </button>
      {prize && !isSpinning && <h2>You got: {prize}</h2>}
    </div>
  );
};

export default FortuneWheel;
