
import { gql } from '@apollo/client';

export const GET_EXP_POINT = gql`
 query GetExperiencePoint($address: String!) {
  experiencePoint(address: $address) {
    count
    level
    nextLevelXp
  }
}
`;


