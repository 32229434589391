import React, { useState, useEffect } from 'react';
import { gql, useQuery } from "@apollo/client";
import { UserExists } from './graphql/query/UserExist';
import {
  TextField, Button, Box, FormControlLabel, Radio, RadioGroup,
  FormControl, FormLabel, Container, Typography, Grid, Paper, IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Login from './Login';
import { useWallet } from '@alephium/web3-react';
import { CREATE_USER, CREATE_ORGANIZATION } from './graphql/mutations/createEntity';
import toast from 'react-hot-toast';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import PostComponent from './components/Posts';
import Navbar from './components/Navbar';
import Launchpad from './components/Launchpad';
import { ANS } from "@alph-name-service/ans-sdk";
import Pulse from './components/Pulse';
import ComingSoon from './components/ComingSoon';
import { isMobile } from 'react-device-detect';
import { Envs } from "./conf/envs";
import Perfil from './components/Perfil';
import ChatComponent from './components/chat/Chat';
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(5),
  background: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '10px',
  color: 'white',
  boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)',
  width: '400px', // Fixed width
  height: 'auto', // Auto height to fit content
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .highlight': {
    fontWeight: 'bold',
    color: 'yellow', // Highlight color
  },
}));

const HighlightedAddressInput = ({ value }) => {
  const addressParts = value.split("");

  const highlightedValue = addressParts.map((char, index) => {
    if (index < 4 || index >= value.length - 4) {
      return (
        <span style={{ color: "#007bff" }} key={index}>{char}</span>
      );
    }
    if (index > 27 && index < 30) return (<span style={{ color: "yellow" }} key={index}>..</span>)
    if (index > 30 && index < 43) return (<span style={{ color: "#007bff" }} key={index}></span>)
    return <span style={{ color: 'white' }} key={index}>{char}</span>;
  });

  return <div>{highlightedValue}</div>;
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'white',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: 'white',
  '& .MuiRadio-root': {
    color: 'white',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'white',
  borderColor: 'white',
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Dashboard = () => {
  const { width, height } = useWindowSize();
  const [setksla, setSet] = useState("social");
  const notify = (message) => toast(message);
  const { account } = useWallet();
  const { address } = account || {};
  const [exists, setExists] = useState(false);
  const [ansName, setAnsName] = useState(null);
  const [ansUri, setAnsUri] = useState(null);

  const token = localStorage.getItem(Envs.TOKEN_NAME);

  const { loading, error, data, refetch } = useQuery(UserExists, {
    variables: { address },
    skip: !address || !token, 
    onCompleted: (data) => {
      setExists(data.userExists);
    },
  });

  const fetchAnsName = async (address) => {
    try {
      const ans = new ANS("testnet");
      const profile = await ans.getProfile(address);
      if (profile?.name) {
        console.log(profile?.name)
        setAnsName(profile.name);
      }
      if (profile?.imgUri) {
        setAnsUri(profile.imgUri);
      }
    } catch (error) {
      console.error("Error fetching ANS:", error);
    }
  };

  useEffect(() => {
    if (address) {
      fetchAnsName(address);
    }
  }, [account]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.message} </p>;

 

  if (data?.userExists) {
    return (
      <>
        <Navbar setSet={setSet} />
        <Grid container direction="column" width="100vw" height="100vh"  alignItems="center">
          {setksla === "social" && <PostComponent name={ansName} imgUri={ansUri} />}
          {setksla === "alphpad" && <Launchpad />}
          {setksla === "pulse" && <ComingSoon />}
          {setksla === "marketplace" && <ComingSoon />}
          {setksla === "perfil" && <Perfil/>}
          {setksla === "chat" && <ChatComponent/>}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Login />
     
    </>
  );
};

const App = () => {
  return <Dashboard />;
};

export default App;
