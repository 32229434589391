import React, { useEffect, useState, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useConnect, useWallet } from '@alephium/web3-react';
import { signMessage } from './services/sign';
import authService from "./services/auth";
import { Envs } from './conf/envs';

const generateNonce = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const AuthenticatedRoute = () => {
  const { account, signer } = useWallet();
  const { disconnect } = useConnect();
  const [val, setVal] = useState(null);
  const [signature, setSignature] = useState(null);
  const [nonce, setNonce] = useState(generateNonce());
  const signingStartedRef = useRef(false);
  const token= localStorage.getItem(Envs.TOKEN_NAME)

  useEffect(() => {
    const performLogin = async () => {
    
      if (val !== null) {
        if (!val) {
          disconnect();
        } else if (val === true && signature) {
          try {
            await authService.login(account.address, account.publicKey, nonce, signature);
          } catch (error) {
            console.error('Login failed:', error);
           
          }
        }
      }
    };
    
    if (val===false) {
      disconnect();
    }
 if(!token)
    performLogin();

  }, [val, disconnect, signature, account, nonce]);

  useEffect(() => {
    const initiateSigning = async () => {
      if (account && !signature && !signingStartedRef.current) {
        signingStartedRef.current = true;
        try {
          await signMessage(nonce, account, signer, setVal, setSignature);
         
        } catch (error) {
          console.error("Signing error", error);
        }
      }
    };
    if(!signature){
    initiateSigning();
   
    return () => {
      signingStartedRef.current = false;
    };}
  }, [account]);

  return account ? <Outlet /> : <Navigate to="/login" />;
};

const UnauthenticatedRoute = () => {
  const { account } = useWallet();
  return account ? <Navigate to="/social" /> : <Outlet />;
};

export { AuthenticatedRoute, UnauthenticatedRoute };
