import { gql } from '@apollo/client';

export const CREATE_POST = gql`
  mutation CreatePost($createPostInput: CreatePostInput!) {
    createPost(createPostInput: $createPostInput) {
      _id
      content
      author
      timestamp
      likes {
        user
        when
      }
      comments {
        id
        content
        user
        timestamp
      }
    }
  }
`;

export const LIKE_POST = gql`
  mutation LikePost($likePostInput: LikePostInput!) {
    likePost(likePostInput: $likePostInput) {
      _id
      likes {
        user
        when
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      _id
      comments {
        id
        content
        user
        timestamp
      }
    }
  }
`;
