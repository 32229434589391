// Updated PostCard.js

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  AppBar,
  Toolbar,
  TextField,
  Chip,
  Grid,
  InputAdornment,
  Fab,
  Divider,
  Avatar,
  Box,
  useMediaQuery,
  Slide,
  Container,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import DOMPurify from 'dompurify';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import { ANS } from '@alph-name-service/ans-sdk';
import { ONE_ALPH, sign } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import ForeignPerfil from './ForeignPerfil';
import { motion } from "framer-motion"
// Styled components for the comment dialog
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#2a2a2a',
  color: '#ffffff',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledSendButton = styled(IconButton)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: '#007bff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#2e2e2e',
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#444444',
    },
    '&:hover fieldset': {
      borderColor: '#666666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007bff',
    },
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#aaaaaa',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ffffff',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#2e2e2e',
  borderRadius: '8px',
  marginBottom: '8px',
  padding: '16px',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#007bff',
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: '16px',
  right: '16px',
  backgroundColor: '#007bff',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  paddingTop: '16px',
  paddingBottom: '16px',
}));

// Transition component for dialog animation
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Function to parse markdown-like syntax
const parseMarkdown = (text) => {
  // Parse links
  let parsedText = text.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:#1e90ff;">$1</a>'
  );

  // Convert **text** to <b>text</b>
  parsedText = parsedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

  // Convert _text_ to <i>$1</i>
  parsedText = parsedText.replace(/_(.*?)_/g, '<i>$1</i>');

  // Convert `text` to <code>$1</code>
  parsedText = parsedText.replace(/`(.*?)`/g, '<code>$1</code>');

  // Sanitize the parsed HTML
  return DOMPurify.sanitize(parsedText);
};

const PostCard = ({
  post,
  currentPostId,
  setCurrentPostId,
  handleLikePost,
  handleCreateComment,
  commentContent,
  setCommentContent,
}) => {
  const { account, signer } = useWallet();

  const [ansName, setAnsName] = useState(null);
  const [ansUri, setAnsUri] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTipDialogOpen, setIsTipDialogOpen] = useState(false);
  const [tipAmount, setTipAmount] = useState('');
  const [commentAnsDetails, setCommentAnsDetails] = useState({});
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [emoji, setEmoji] = useState('');
  const [loadingComments, setLoadingComments] = useState(false);

  const fullScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchAnsName = async () => {
      try {
        const ans = new ANS('testnet');
        const profile = await ans.getProfile(post.author);
        if (profile?.name) {
          setAnsName(profile.name);
        }
        if (profile?.imgUri) {
          setAnsUri(profile.imgUri);
        }
      } catch (error) {
        console.error('Error fetching ANS profile:', error);
      }
    };
    fetchAnsName();
  }, [post.author]);

  useEffect(() => {
    const fetchCommentAnsDetails = async () => {
      setLoadingComments(true);
      try {
        const ans = new ANS('testnet');
        const details = {};
        for (const comment of post.comments) {
          const profile = await ans.getProfile(comment.user);
          details[comment.user] = {
            name: profile?.name || null,
            imgUri: profile?.imgUri || null,
          };
        }
        setCommentAnsDetails(details);
      } catch (error) {
        console.error('Error fetching ANS for comments:', error);
      } finally {
        setLoadingComments(false);
      }
    };
    fetchCommentAnsDetails();
  }, [post.comments]);

  // Fetch balance and determine emoji
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await fetch(
          `https://node.mainnet.alephium.org/addresses/${post.author}/balance`
        );
        const data = await response.json();
        const balanceStr = data.balance;

        // Convert balance from string to number with 18 decimals
        const balanceAmount = parseFloat(balanceStr) / 1e18;

        // Round to 2 decimal places
        const roundedBalance = Math.round(balanceAmount * 100) / 100;

        // Determine emoji based on balance
        let newEmoji = '';
        if (roundedBalance < 10) {
          newEmoji = '🦐'; // Shrimp
        } else if (roundedBalance < 100) {
          newEmoji = '🐟'; // Fish
        } else if (roundedBalance < 1000) {
          newEmoji = '🦈'; // Shark
        } else {
          newEmoji = '🐳'; // Whale
        }

        setEmoji(newEmoji);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    if (post.author) {
      fetchBalance();
    }
  }, [post.author]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentPostId(null);
  };

  const handleCommentIconClick = () => {
    setCurrentPostId(post._id);
    handleOpenDialog();
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTipClick = () => {
    setIsTipDialogOpen(true);
  };

  const handleCloseTipDialog = () => {
    setIsTipDialogOpen(false);
    setTipAmount('');
  };

  const handleSendTip = async () => {
    try {
      if (!account) {
        console.log("no account", account)
        return;
      }

      const senderAddress = account.address;
      const receiverAddress = post.author;

      // Convert the tip amount from string to BigInt in attoALPH
      const tipAmountNumber = Number(tipAmount);
      if (isNaN(tipAmountNumber) || tipAmountNumber <= 0) {
        alert('Please enter a valid numeric tip amount.');
        return;
      }
      const tipAmountBigInt =
        (BigInt(Math.floor(tipAmountNumber * 1e10)) * ONE_ALPH) / BigInt(1e10);

      // Sign and submit the transfer transaction directly using the wallet's signer
      const txResult = await signer.signAndSubmitTransferTx({
        signerAddress: senderAddress,
        destinations: [
          { address: receiverAddress, attoAlphAmount: tipAmountBigInt },
        ],
      });

      console.log('Tip sent successfully, transaction id:', txResult.txId);
      alert('Tip sent successfully!');
    } catch (error) {
      console.error('Error sending tip:', error);
      alert('Error sending tip. Please try again.');
    } finally {
      handleCloseTipDialog();
    }
  };

  function formatTimestamp(milliseconds) {

    const date = new Date(parseInt(milliseconds, 10));
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }


  const highlightAddress = (address) => {
    if (!address) return '';
    const start = `${address.slice(0, 5)}`;
    const end = `${address.slice(-4)}`;
    return (
      <span>
        <a style={{ color: '#007bff' }}>{start}</a>
        <a style={{ color: '#ffffff' }}>...{end}</a>
      </span>
    );
  };

  const handleOpenProfileDialog = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };

  return (
    <Card
      sx={{
        marginTop: 2,
        boxShadow: 0,
        backgroundColor: 'rgba(10, 10, 10,0.15)',
        borderRadius: 4,
        color: '#ffffff',
      }}
    >
      <CardContent>
        <Grid container direction="row" alignItems={'center'}>
          <Grid item>
            <Typography variant="body2" color="#6C7A89">
              by:
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleOpenProfileDialog}>
              <Chip
                label={
                  <Typography variant="subtitle2" component="div">
                    {emoji}{' '}
                    {ansName ? ansName : highlightAddress(post.author)}
                  </Typography>
                }
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Typography mt={3} variant="body1" sx={{ marginBottom: 2 }} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: isExpanded
                ? parseMarkdown(post.content)
                : `${parseMarkdown(post.content.slice(0, 100))}${post.content.length > 100 ? '...' : ''
                }`,
            }}
          />
        </Typography>
        <Typography variant="body2" color="#6C7A89">
          {formatTimestamp(post.createdAt)}
        </Typography>
        {post.content.length > 100 && (
          <Button onClick={handleExpandClick} sx={{ color: '#007bff', textTransform: 'none' }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {isExpanded ? 'Show Less' : 'Read More'}
          </Button>
        )}
      </CardContent>
      <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}></Divider>
      <CardActions>
        <motion.div
          whileTap={{ scale: 0.9 }}
          whileHover={{
            scale: 1.2,
            color: "#007bff",
            rotate: -10,
          }}
        >
          <IconButton size="small" color="primary" onClick={() => handleLikePost(post._id)}>
            <HandshakeIcon />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              {post.likes.length}
            </Typography>
          </IconButton>
        </motion.div>
        <IconButton size="small" color="primary" onClick={handleCommentIconClick}>
          <CommentIcon />
          <Typography variant="body2" sx={{ marginLeft: 1 }}>
            {post.comments.length}
          </Typography>
        </IconButton>
        {post.author !== account.address && (
          <Button size="small" color="primary" onClick={handleTipClick}>
            <AttachMoneyIcon /> Tip
          </Button>
        )}
      </CardActions>

      {/* Profile Dialog */}
      <StyledDialog
        open={isProfileDialogOpen}
        onClose={handleCloseProfileDialog}
        fullScreen
        TransitionComponent={Transition}
      >
        <ForeignPerfil address={post.author} />
        <StyledFab
          aria-label="close"
          onClick={handleCloseProfileDialog}
        >
          <CloseIcon />
        </StyledFab>
      </StyledDialog>

      {/* Comments Dialog */}
      <StyledDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >


        <StyledContainer>
          {loadingComments ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
              <CircularProgress style={{ color: '#007bff' }} />
            </Box>
          ) : post.comments.length === 0 ? (
            <Typography variant="body1" align="center" color="#aaaaaa">
              No comments yet. Be the first to comment!
            </Typography>
          ) : (
            <List>
              {post.comments.map((comment) => (
                <StyledListItem key={comment.id}>
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid item>
                      <StyledAvatar
                        alt={commentAnsDetails[comment.user]?.name || 'User'}
                        src={commentAnsDetails[comment.user]?.imgUri || null}
                      >
                        {commentAnsDetails[comment.user]?.name
                          ? commentAnsDetails[comment.user].name.charAt(0).toUpperCase()
                          : 'U'}
                      </StyledAvatar>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                        {commentAnsDetails[comment.user]?.name ||
                          highlightAddress(comment.user)}
                      </Typography>

                      <Typography variant="body1" style={{ color: '#ffffff' }}>
                        {comment.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledListItem>
              ))}
            </List>
          )}
        </StyledContainer>

        <Box
          sx={{
            padding: '16px',
            backgroundColor: '#1e1e1e',
            position: 'sticky',
            bottom: 0,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <StyledTextField
                placeholder="Write a comment..."
                variant="outlined"
                fullWidth
                multiline
                maxRows={4}
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledSendButton
                        onClick={() => handleCreateComment(post._id)}
                      >
                        <SendIcon />
                      </StyledSendButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledDialog>

      {/* Tip Dialog */}

  <StyledDialog
  open={isTipDialogOpen}
  onClose={handleCloseTipDialog}
  TransitionComponent={Transition}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle
    style={{
      background: '',
      color: '#ffffff',
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '16px 0',
      boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
    }}
  >
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4 }}
    >
      Send a Tip
    </motion.div>
  </DialogTitle>
  <DialogContent
    style={{
      backgroundColor: '#1e1e1e',
      color: '#ffffff',
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Typography
      variant="h6"
      style={{
        marginBottom: '16px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      Choose or Enter Tip Amount
    </Typography>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      style={{ display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap' }}
    >
      {[0.5, 1, 2, 5, 10].map((amount) => (
        <Chip
          key={amount}
          label={`${amount} ALPH`}
          onClick={() => setTipAmount(amount.toString())}
          style={{
            backgroundColor: tipAmount === amount.toString() ? '#007bff' : 'transparent',
            color: tipAmount === amount.toString() ? '#ffffff' : '#007bff',
            border: `2px solid #007bff`,
            borderRadius: '30px',
            fontWeight: 'bold',
            padding: '8px 16px',
            cursor: 'pointer',
            boxShadow: tipAmount === amount.toString() ? '0 0 15px rgba(0, 123, 255, 0.6)' : 'none',
            transition: 'all 0.3s ease-in-out',
          }}
          component={motion.div}
          whileHover={{
            scale: 1.1,
            boxShadow: '0 0 20px rgba(0, 123, 255, 0.8)',
          }}
        />
      ))}
    </motion.div>
    <Typography
      variant="body2"
      style={{
        margin: '16px 0',
        textAlign: 'center',
        color: '#aaaaaa',
      }}
    >
      Or enter a custom amount:
    </Typography>
    <StyledTextField
      placeholder="Enter amount in ALPH"
      variant="outlined"
      fullWidth
      value={tipAmount}
      onChange={(e) => setTipAmount(e.target.value)}
      sx={{
        mb: 2,
      }}
    />
  </DialogContent>
  <DialogActions
    style={{
      background: '',
      justifyContent: 'space-around',
      padding: '16px',
    }}
  >
    <motion.div whileHover={{ scale: 1.1 }}>
      <Button
        onClick={handleCloseTipDialog}
        style={{
          backgroundColor: 'transparent',
          color: '#ffffff',
          border: '2px solid #ffffff',
          borderRadius: '30px',
          padding: '10px 20px',
          fontWeight: 'bold',
          boxShadow: '0 0 10px rgba(255, 255, 255, 0.3)',
          transition: 'all 0.3s ease-in-out',
        }}
        component={motion.div}
        whileHover={{
          backgroundColor: '#ffffff',
          color: '#1e1e1e',
          boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
        }}
      >
        Cancel
      </Button>
    </motion.div>
    <motion.div whileHover={{ scale: 1.1 }}>
      <Button
        onClick={handleSendTip}
        style={{
          backgroundColor: '#007bff',
          color: '#ffffff',
          borderRadius: '30px',
          padding: '10px 20px',
          fontWeight: 'bold',
          boxShadow: '0 0 15px rgba(0, 123, 255, 0.6)',
          transition: 'all 0.3s ease-in-out',
        }}
        component={motion.div}
        whileHover={{
          backgroundColor: '#0056b3',
          boxShadow: '0 0 20px rgba(0, 123, 255, 0.8)',
        }}
      >
        Send Tip
      </Button>
    </motion.div>
  </DialogActions>
</StyledDialog>


    </Card>
  );
};

export default PostCard;
